/*
0. Specific
1. Misc
2. Objects
3. Positioning
4. Margin and Padding
5. Hovers
6. Flexes
7. Media Queries
*/

* {
  box-sizing: border-box;
}

body {
  background: #2c2831;
  color: #d5d4d6;
}

/*
---------------------------------------------
SPECIFIC
---------------------------------------------
*/

.cardlist {
  padding-top: 157px;
}

.navbarr {
  margin-left: 5px;
  width: 93.5%;
  padding-top: 70px;
  position: fixed;
}

.navmaster {
  border-style: solid;
  border-width: 1px;
  border-color: #db5858;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: fixed;
  top: 0;
  width: 96.5%;
}

.search {
  margin-left: 5px;
  width: 93%;
  padding-top: 128px;
  position: fixed;
}


.navsearchcontainer {
  padding-top: 70px;
}

.footer {
  width: 97.5%;
  border-style: solid;
  border-width: 1px;
  border-color: #9c4646;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.creditsLink {
  padding-left: 5px;
  padding-right: 5px;
}

/*
---------------------------------------------
MISC
---------------------------------------------
*/

.bordered {
  border-style: solid;
  border-width: 1px;
  border-color: #9c4646;
  border-radius: 8px;
}

.clearborder {
  outline: none;
  border-style: none;
}

.fontsize8 {
  font-size: 14px
}

.fontsize18 {
  font-size: 18px
}

.fontsize {
  font-size: 20px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize48 {
  font-size: 48px;
}

.modalbackground {
  background-color: #2c2831;
}

.orangetext {
  color: #db5858;
}

.scaledown80 {
  transform: scale(0.8);
}

.scaleup125 {
  transform: scale(1.25);
}

.scaleup150 {
  transform: scale(1.5);
}

.textarea {
  background-color: #2c2831;
  border-style: solid;
  border-width: 1px;
  border-color: #9c4646;
  border-radius: 8px;
  width: 100%;
}


.whitespace {
  white-space: pre-wrap;
}

/*
------------------------------------------------------------------
  OBJECTS
------------------------------------------------------------------
*/

.button {
  background: #2c2831;
  color: #db5858;
  cursor: pointer;
  transition: 0.3s;
}

.button--selected {
  background: #2c2831;
  color: #d5d4d6;
  cursor: pointer;
}

.button--override {
  color: #d5d4d6;
  cursor: default;
}

.cardname {
  font-size: 24px;
  margin-left: 15px;
  padding: 5px;
}

.charsheet {
  display: flex;
  flex-direction: row;
}

.icon {
  background: #2c2831;
  color: #db5858;
  cursor: pointer;
  transform: scale(1.5);
  margin-left: 18px;
  transition: 0.3s;
}

.iconbutton {
  background: #2c2831;
  color: #db5858;
  cursor: pointer;
  transform: scale(1.5);
  transition: 0.3s;
}

.iconsvg {
  width: 30px;
  color: #db5858;
  cursor: pointer;
  transition: 0.3s;
}

.iconsvgselected {
  width: 30px;
  color: #d5d4d6;
  cursor: pointer;
  transition: 0.3s;
}

.landingtitlesvg {
  width: 200px;
  height: 60px;
  color: #d5d4d6;
}

.titlesvg {
  width: 200px;
  height: 60px;
  color: #db5858;
  cursor: pointer;
  transition: 0.3s;
}

.titlesvgselected {
  width: 200px;
  height: 60px;
  color: #d5d4d6;
  cursor: pointer;
  transition: 0.3s;
}

.heading {
  margin-top: 4px;
  margin-left: 0px;
}

.hoverpointer {
  cursor: default;
}

hr {
  border-color: #d5d4d6;
  padding: 3px !important;
  margin: 3px !important;
}

.link {
  margin: 2px;
  padding: 2px;
  float: right;
  margin-left: 3px;
}

.outerbox {
  border-style: solid;
  border-width: 1px;
  border-color: #9c4646;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.plusminus {
  margin: 5px;
  padding: 5px;
  float: right;
  background: #2c2831;
  color: #db5858;
  cursor: pointer;
}

#searchbox {
  width: 100%;
}

.searchbutton {
  min-width: 40px;
}

/*
------------------------------------------------------------------
  POSITIONING
------------------------------------------------------------------
*/

.center {
  text-align: center;
}

.centerchild {
  display: flex;
  justify-content: center;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

.fullwidth {
  width: 100%;
}

.lefttoright {
  text-align: right;
}

.limitwidth {
  width: 700px;
  max-width: 31%;
}

.limitwidthworld {
  width: 700px;
  max-width: 31%;
}

.maxwidth {
  width: fit-content;
}

.rightfloat {
  float: right;
  margin-left: auto;
}

.textwrap {
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* 
-------------------------------------------------------------------
MARGIN AND PADDING
-------------------------------------------------------------------
*/

.margin5px {
  margin: 5px;
}

.mbottom5px {
  margin-bottom: 5px;
}

.mbottom20px {
  margin-bottom: 20px;
}

.mleft0px {
  margin-left: 0px;
}

.mleft5px {
  margin-left: 5px;
}

.mleft8px {
  margin-left: 8px;
}

.mleft12px {
  margin-left: 12px;
}

.mleft15px {
  margin-left: 15px;
}

.mleft20px {
  margin-left: 20px;
}

.mleft30px {
  margin-left: 30px;
}

.mleft40px {
  margin-left: 40px;
}

.mright3px {
  margin-right: 3px;
}

.mright8px {
  margin-right: 8px;
}

.mright12px {
  margin-right: 12px;
}

.mright15px {
  margin-right: 15px;
}

.mright24px {
  margin-right: 24px;
}

.mtop4px {
  margin-top: 4px;
}

.mtop45px {
  margin-top: 4.5px;
}

.mtop8px {
  margin-top: 8px;
}

.mtop10px {
  margin-top: 10px;
}

.mtop12px {
  margin-top: 12px;
}

.mtop100px {
  margin-top: 70px;
}

.padded2px {
  margin: 2px;
  padding: 2px;
}

.padded5px {
  padding: 5px;
}

.pbottom5px {
  padding-bottom: 5px;
}

.pbottom8px {
  padding-bottom: 8px;
}

.pleft5px {
  padding-left: 5px;
}

.pright5px {
  padding-right: 10px;
}

.ptop5px {
  padding-top: 5px;
}

.ptop50px {
  padding-right: 50px;
}

/*
-------------------------------------------------------------------------
  HOVERS
-------------------------------------------------------------------------
*/

a:hover {
  text-decoration: none;
  color: #d5d4d6;
}

button:hover {
  text-decoration: none;
  color: #d5d4d6;
}

.button:hover {
  text-decoration: none;
  color: #d5d4d6;
}

.button--selected:hover {
  text-decoration: none;
  color: #d5d4d6;
  cursor: default;
}

.icon:hover {
  text-decoration: none;
  color: #d5d4d6;
}

.iconsvg:hover {
  color: #d5d4d6;
}

.titlesvg:hover {
  color: #d5d4d6;
}

.hoverpointer:hover {
  cursor: pointer;
}

.outerbox:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/*
----------------------------------------------------------------------
  FLEXES
----------------------------------------------------------------------
*/

.flex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.flexcol {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.flexgrow {
  flex: 1 1 0;
}

.flexgrow2 {
  flex: auto;
}

.flexgrow3 {
  flex: 2 0 0;
}

.flexstart {
  flex: start;
}

/*
----------------------------------------------------------------------
  MEDIA QUERIES
----------------------------------------------------------------------
*/

@media (max-width: 992px) {
  .charsheet {
    flex-direction: column;
  }

  .none992 {
    display: none;
  }

  .limitwidth {
    width: 97.5%;
    max-width: 97.5%;
  }

  .limitwidthworld {
    width: 97.5%;
    max-width: 93.5%;
  }
}

@media (min-width: 991px) {
  .outerbox992 {
    border-style: solid;
    border-width: 1px;
    border-color: #9c4646;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
}

@media (max-width: 400px) {
  .cardname {
    font-size: 20px;
    margin-left: 15px;
    padding: 5px;
  }

  .fontsize {
    font-size: 16px;
  }

  .icon {
    transform: scale(1.2);
  }

  .searchbutton {
    min-width: 40px;
  }
}